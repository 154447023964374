/* eslint-disable */

import { APIClient } from "./_api";
const endpoint = "/OrderConfirmationForm";

export function getAll(query) {
  return APIClient.post(`${endpoint}/filter`, query);
}

export function edit(values) {
  return APIClient.put(`${endpoint}/${values.id}`, values.data);
}

export function create(value) {
  return APIClient.post(`${endpoint}`, value);
}
export function remove(id) {
  return APIClient.delete(`${endpoint}/${id}`);
}
