/** @format */
/* eslint-disable  */

import axios from "axios";

const { REACT_APP_BASENAME } = process.env;

function setUrl() {
  return `${REACT_APP_BASENAME}/client/v1`;
}
const baseURL = setUrl();

const APIClient = axios.create({ baseURL });

// function setAuthToken(token) {
//   if (
//     APIClient.defaults.headers.Authorization === "" ||
//     APIClient.defaults.headers.Authorization === undefined
//   ) {
//     APIClient.defaults.headers["Cache-Control"] = "no-cache";
//     APIClient.defaults.headers.Pragma = "no-cache";
//     APIClient.defaults.headers.Authorization = `Bearer ${token}`;
//   }
// }

function setAuthToken(token) {
  if (token) {
    APIClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    APIClient.defaults.headers.common["Cache-Control"] = "no-cache";
    APIClient.defaults.headers.common["Pragma"] = "no-cache";
  }
}

function unsetAuthToken() {
  delete APIClient.defaults.headers.Authorization;
}

export { APIClient, setAuthToken, unsetAuthToken, baseURL };
