/* eslint-disable  */

import { APIClient } from "./_api";
const endpoint = "/User";

export function getAll(values) {
  return APIClient.post(`${endpoint}/users/filter`, values);
}

export function getAllUserNoFilter() {
  return APIClient.get(`${endpoint}/users`);
}
export function getAllMenu(values) {
  return APIClient.get(`${endpoint}/menu`, values);
}

export function getAllSaleManager() {
  return APIClient.get(`${endpoint}/saleManager`);
}

export function getAllDeleted(values) {
  return APIClient.post(`${endpoint}/users/deleted/filter`, values);
}
export function getAllRecruiterUser(values) {
  return APIClient.get(`${endpoint}/recruiterUser/users/${values}`);
}

export function editByAdmin(values) {
  return APIClient.put(`${endpoint}/admin`, values);
}

export function edit(values) {
  return APIClient.put(`${endpoint}`, values);
}

export function avatar(values) {
  return APIClient.put(`${endpoint}/avatar`, values);
}

export function getAllDriver() {
  return APIClient.get(`${endpoint}/driver`);
}

export function deleteItem(id) {
  return APIClient.delete(`${endpoint}/admin/${id}`);
}

export function unbanItem(id) {
  return APIClient.put(`${endpoint}/restore/${id}`);
}

export function activeUser(values) {
  return APIClient.put(`${endpoint}/recruiterUser/accept`, values);
}

export function declineUser(values) {
  return APIClient.put(
    `${endpoint}/recruiterUser/decline/${values.userId}`,
    values.data
  );
}

export function banUser(values) {
  return APIClient.post(`/BanList`, values);
}

// AgentAssistant
export function getAllAgentAssistant(values) {
  //filter
  // return APIClient.post(`/AgentAssistant/filter`, values);
  return APIClient.get(`/AgentAssistant`);
}

export function createAgentAssistant(values) {
  return APIClient.post(`/AgentAssistant`, values);
}

export function deleteAgentAssistant(id) {
  return APIClient.delete(`/AgentAssistant/${id}`);
}

export function create(value, func) {
  const bodyFormData = new FormData();
  for (const key in value) {
    bodyFormData.append(key, value[key]);
  }
  return APIClient.post(`${endpoint}/create`, bodyFormData, {
    onUploadProgress(progress) {
      if (func) {
        func(progress);
      }
    },
  });
}
