/* eslint-disable */

import { APIClient } from "./_api";

const endpoint = "/Order";

export function getOrders(query) {
  return APIClient.post(`${endpoint}/filter`, query);
}
export function getMetaData(query) {
  return APIClient.post(`${endpoint}/filter/meta`, query);
}
export function getSalePipelineMetaData(query) {
  return APIClient.post(`${endpoint}/salePipeline/filter/meta`, query);
}
export function getAllCategorySearch() {
  return APIClient.get(`${endpoint}/distinct`);
}

export function getOrderByOrderNum(orderNum) {
  return APIClient.get(`${endpoint}/ordernum/${orderNum}`);
}

export function getOrdersSalesPipline(query) {
  return APIClient.post(`${endpoint}/salePipeline/filter`, query);
}

export function getOrder(id) {
  return APIClient.get(`${endpoint}/id/${id}`);
}

export function getOrderClient(id) {
  return APIClient.get(`${endpoint}/client/${id}`);
}
export function getOrderClientByUser(id) {
  return APIClient.get(`${endpoint}/clientToken/${id}`);
}

export function getAddressFromZip(zipCode) {
  return APIClient.get(`${endpoint}/zip/${zipCode}`);
}

export function getInvoice(data) {
  return APIClient.post(`${endpoint}`, data);
}

export function getRoles() {
  return APIClient.get(`/Role`);
}

export function registerCustomer(data) {
  return APIClient.post(`/Customer/register`, data);
}

export function updateCustomerInfo(data, id) {
  return APIClient.put(`/Customer/${id}`, data);
}

export function getCustomer(data) {
  return APIClient.post(`/Customer/email`, data);
}
export function finalize(data) {
  return APIClient.post(`${endpoint}/orderItem`, data);
}
export function finalizeAgent(data) {
  return APIClient.post(`${endpoint}/orderItem/agent`, data);
}

export function getInventoryOrder(values) {
  return APIClient.post(`/InventoryOrder/filter`, values);
}

export function getOrderNote(values) {
  return APIClient.post(`${endpoint}Note/filter`, values);
}

export function addLogisticToOrder(values) {
  return APIClient.post(`/Logistic`, values);
}

export function acceptErrorLogistic(values) {
  return APIClient.post(`/Logistic/acceptError`, values);
}

export function getLogisticOrder(values) {
  return APIClient.post(`/Logistic/filter`, values);
}
export function deleteLogisticOrder(id) {
  return APIClient.delete(`/Logistic/${id}`);
}
export function editLogisticOrder(id, data) {
  return APIClient.put(`/Logistic/${id}`, data);
}

export function addAccountingToOrder(values) {
  return APIClient.post(`/OrderAccounting`, values);
}
export function getAccountingOrder(id) {
  return APIClient.get(`/OrderAccounting/orderId/${id}`);
}

export function getBillingOrder(id) {
  return APIClient.get(`/BillingInformation/orderId/${id}`);
}

export function editLabelOrder(id, data) {
  return APIClient.put(`${endpoint}/label/${id}`, data);
}

export function editManagerOrder(id, data) {
  return APIClient.put(`${endpoint}/manager/${id}`, data);
}

export function editSellerOrder(id, data) {
  return APIClient.put(`${endpoint}/seller/${id}`, data);
}

export function resendOrder(id) {
  return APIClient.post(`${endpoint}/resendInvoice/${id}`);
}
export function resendInvoiceToDriver(id) {
  return APIClient.post(`/Logistic/resendEmailToDriver`, id);
}

export function editCustomAmount(id, data) {
  return APIClient.put(`${endpoint}/customAmount/${id}`, data);
}

export function getSumUp(id) {
  return APIClient.get(`${endpoint}/sum/sumUp`);
}
