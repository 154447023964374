/* eslint-disable */
import { APIClient } from "./_api";

const endpoint = "/User";

export function getUser(id) {
  return APIClient.get(`/UserPage/id/${id}`);
}

export function login(data) {
  return APIClient.post(`${endpoint}/login/website`, data);
}

export function getMe() {
  return APIClient.get(`${endpoint}/me`);
}

export function loginFacebook(data) {
  return APIClient.post(`${endpoint}/facebook/login`, data);
}

export function connectFacebook(data) {
  return APIClient.post(`${endpoint}/facebook/connect`, data);
}

export function completeProfile(data) {
  return APIClient.put(`${endpoint}/facebook/complete`, data);
}

export function forgetPassword(data) {
  return APIClient.post(`${endpoint}/forgetPassword`, data);
}

export function editProfile(data) {
  return APIClient.put(`${endpoint}`, data);
}
export function getInveiteLink() {
  return APIClient.get(`${endpoint}/InviteLink`);
}

export function addImg(value, func) {
  const bodyFormData = new FormData();
  for (const key in value) {
    bodyFormData.append(key, value[key]);
  }
  return APIClient.put(`${endpoint}/avatar`, bodyFormData, {
    onUploadProgress(progress) {
      if (func) {
        func(progress);
      }
    },
  });
}
