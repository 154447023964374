// ** Redux Imports
/* eslint-disable */
import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { users } from "@api/Api";
import {
  Mail,
  MessageSquare,
  CheckSquare,
  Calendar,
  FileText,
  Circle,
  ShoppingCart,
  User,
  Shield,
  Square,
  Box,
  DollarSign,
  Percent,
  Star,
  Map,
  Slash,
  Layout,
} from "react-feather";

// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig";

export const getAllMenu = createAsyncThunk("layout/getAllMenu", async () => {
  try {
    return users.getAllMenu();
  } catch (error) {
    console.error(error);
  }
});
export const removeMenu = createAsyncThunk("layout/removeMenu", async () => {
  try {
    return [];
  } catch (error) {
    console.error(error);
  }
});

const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem("menuCollapsed");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

const initialDirection = () => {
  const item = window.localStorage.getItem("direction");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.isRTL;
};

const initialSkin = () => {
  const item = window.localStorage.getItem("skin");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.skin;
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    menu: [],
    menuFetched: false,
    skin: initialSkin(),
    isRTL: initialDirection(),
    layout: themeConfig.layout.type,
    lastLayout: themeConfig.layout.type,
    menuCollapsed: initialMenuCollapsed(),
    footerType: themeConfig.layout.footer.type,
    navbarType: themeConfig.layout.navbar.type,
    menuHidden: themeConfig.layout.menu.isHidden,
    contentWidth: themeConfig.layout.contentWidth,
    navbarColor: themeConfig.layout.navbar.backgroundColor,
  },
  reducers: {
    handleRTL: (state, action) => {
      state.isRTL = action.payload;
      window.localStorage.setItem("direction", JSON.stringify(action.payload));
    },
    handleSkin: (state, action) => {
      state.skin = action.payload;
      window.localStorage.setItem("skin", JSON.stringify(action.payload));
    },
    handleLayout: (state, action) => {
      state.layout = action.payload;
    },
    handleFooterType: (state, action) => {
      state.footerType = action.payload;
    },
    handleNavbarType: (state, action) => {
      state.navbarType = action.payload;
    },
    handleMenuHidden: (state, action) => {
      state.menuHidden = action.payload;
    },
    handleLastLayout: (state, action) => {
      state.lastLayout = action.payload;
    },
    handleNavbarColor: (state, action) => {
      state.navbarColor = action.payload;
    },
    handleContentWidth: (state, action) => {
      state.contentWidth = action.payload;
    },
    handleMenuCollapsed: (state, action) => {
      state.menuCollapsed = action.payload;
      window.localStorage.setItem(
        "menuCollapsed",
        JSON.stringify(action.payload)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMenu.fulfilled, (state, action) => {
        const iconComponents = {
          Mail,
          MessageSquare,
          CheckSquare,
          Calendar,
          FileText,
          Circle,
          ShoppingCart,
          User,
          Shield,
          Square,
          Box,
          DollarSign,
          Percent,
          Star,
          Map,
          Slash,
          Layout,
        };

        const modifiedData = action.payload?.data?.data.map((item) => ({
          ...item,
          icon: React.createElement(iconComponents[item.iconName], {
            size: 20,
          }),
          id: item.key,
        }));

        for (const item of modifiedData) {
          if (item.children.length === 0) {
            delete item.children;
          }
          delete item.iconName;
          delete item.iconSize;
          delete item.sort;
        }

        state.menu = modifiedData;
        state.menuFetched = true;
      })
      .addCase(removeMenu.fulfilled, (state, action) => {
        state.menuFetched = false;
        state.menu = [];
      });
  },
});

export const {
  handleRTL,
  handleSkin,
  handleLayout,
  handleLastLayout,
  handleMenuHidden,
  handleNavbarType,
  handleFooterType,
  handleNavbarColor,
  handleContentWidth,
  handleMenuCollapsed,
} = layoutSlice.actions;

export default layoutSlice.reducer;
