/* eslint-disable  */

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { order, container } from "@api/Api";

export const finalize = createAsyncThunk(
  "appOrder/finalize",
  async (values) => {
    try {
      return order.finalize(values);
    } catch (error) {
      console.error(error);
    }
  }
);
export const finalizeAgent = createAsyncThunk(
  "appOrder/finalizeAgent",
  async (values) => {
    try {
      return order.finalizeAgent(values);
    } catch (error) {
      console.error(error);
    }
  }
);

export const getContainers = createAsyncThunk(
  "appOrder/getContainers",
  async () => {
    return container.getAllContainer();
  }
);

export const appOrderSlice = createSlice({
  name: "appOrder",
  initialState: {
    containers: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContainers.fulfilled, (state, action) => {
      state.containers = action.payload?.data?.data;
    });
  },
});

export default appOrderSlice.reducer;
