/* eslint-disable */

import { APIClient } from "./_api";
const endpoint = "/ContainerPricing";

export function getContainerByCondition(query) {
  return APIClient.post(`/Container/filter`, query);
}

export function getAll(query) {
  return APIClient.post(`${endpoint}/filter`, query);
}

export function getAllContainer() {
  return APIClient.get(`/Container`);
}

export function getOne() {
  return APIClient.get(`${endpoint}`);
}

export function remove() {
  return APIClient.delete(`${endpoint}`);
}

export function create(values) {
  return APIClient.post(`${endpoint}`, values);
}

export function active(value) {
  return APIClient.put(`${endpoint}/${value.id}`, { isActive: value.isActive });
}
